module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","display":"standalone","icon":"src/base/assets/favicons/arc_logo.png","name":"ArcHome","short_name":"ARC","start_url":"/","theme_color":"#0096D7","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"466d48f0305b81e9a96a645383a0c1da"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
